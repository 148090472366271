import Vue from "vue";
const url = require('url');

export default {
	version: "4.9",

	get_base_url: function(){
		// return "https://secure.bytimer.com/api/index.php/";
		return "http://bytimer.brk-dev.ro/api/index.php/";
	},

	set_token: function(newToken) {
		window.localStorage.setItem("pontaj-token", newToken);
		Vue.http.headers.common["Token"] = newToken;
	},
	get_token: function() {
		return window.localStorage.getItem("pontaj-token");
	},

	set_id_firma: function(newId) {
		window.localStorage.setItem("pontaj-id-firma", newId);
		Vue.http.headers.common["XF"] = newId;
	},
	get_id_firma: function() {
		return window.localStorage.getItem("pontaj-id-firma");
	},

	is_logged_in: function() {
		var token = this.get_token();
		return token !== "";
	},
	logout: function() {
		this.set_token("");
	},
	verify_response: function(response) {
		if (response.body.NotLogged) {
			return false;
		}
		return true;
	},
	verify_login_and_redirect: function(vueInstance) {
		if (!this.is_logged_in()) {
			vueInstance.$router.push("/");
		}
	},

	

	checkTimeRule( rule, value, callback) {
		var is_numeric = function(n){
			return !isNaN(parseFloat(n)) && isFinite(n);
		};
        if (!value) {
            return callback(new Error('Va rugam sa introduceti o valoare'));
        }
        let is_hh_mm    = value.toString().length == 5 && value.toString().indexOf(':') == 2;
        let is_hh_mm_ss = value.toString().length == 8 && value.toString().indexOf(':') == 2 && value.toString().lastIndexOf(':') == 5;


        if( is_hh_mm || is_hh_mm_ss )
        {
			let parts = value.toString().split(':');
			if(is_numeric(parts[0]) && is_numeric(parts[1]) )
			{
				let hours 	 = parseInt( parts[0] );
				let mins  	 = parseInt( parts[1] );
				let hours_ok = hours >= 0 && hours <= 24;
				let mins_ok  = mins  >= 0 && mins  < 60;

				if ( is_hh_mm ) 
				{
					if( hours_ok && mins_ok )
						callback();
					else
						callback(new Error('Valoare incorecta'));
				} 
				if( is_hh_mm_ss )
				{
					if( is_numeric(parts[2]) )
					{
						let seconds  = parseInt( parts[2] );
						let secs_ok  = seconds  >= 0 && seconds  < 60;
						if( hours_ok && mins_ok && secs_ok )
							callback();
						else
							callback(new Error('Valoare incorecta'));
					}
					else
					{
						callback(new Error('Valoare incorecta'));
					}
				}
			}
			else
			{
				callback(new Error('Valoare incorecta'));
			}
        }
        else 
        {
            callback(new Error('Valoare incorecta'));
        }
    }
};
